import { useCallback, useEffect, useState } from 'react';

export const useFilterScrollHandlers = (
  filterRef: React.RefObject<HTMLDivElement | null>,
) => {
  const filter = filterRef.current;
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const handleScroll = useCallback(() => {
    if (filter) {
      const scroll = calculateScrollPercentage(filter);
      setScrollPercentage(!Number.isNaN(scroll) ? scroll : 0);
    }
  }, [filter]);

  useEffect(() => {
    if (filter) {
      filter.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (filter) {
        filter.removeEventListener('scroll', handleScroll);
      }
    };
  }, [filter, handleScroll]);

  return { scrollPercentage };
};

const calculateScrollPercentage = (filter: HTMLDivElement) => {
  const maxScrollLeft = filter.scrollWidth - filter.clientWidth;

  const currentScroll = filter.scrollLeft;

  const scrollPercent = (currentScroll / maxScrollLeft) * 100;

  return scrollPercent;
};
