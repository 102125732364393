/* eslint-disable @volvo-cars/css/no-custom-class */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { getMarketSite, SiteSlug } from '@volvo-cars/market-sites';
import { useTracker } from '@volvo-cars/tracking';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { Retailers, RetailerCard, RetailersHandle } from './Retailers';
import { Dictionaries, fetchRetailer, fetchRetailers } from './dataFetching';
import { Coordinate, Retailer } from './types';
import { PlacesHandle, PlacesInner, RetailerPickerConfig } from './Places';
import { RetailerPickerTranslationProvider } from './RetailerPickerTranslationProvider';
import { PlaceAutocompleteResult } from '@googlemaps/google-maps-services-js';

interface TrackingRecords {
  onSearch?: Record<string, string | number | undefined>;
  onGeoClicked?: Record<string, string | number | undefined>;
}

export interface RetailerPickerInnerProps {
  retailerSelected: (retailer: Retailer | null) => void;
  config?: RetailerPickerConfig;
  onAutocompleteChange?: (search: string) => void;
  onError?: (reason: string, error: unknown) => void;
  required?: boolean;
  preselectedParmaCode?: string;
  smallMarketsGeoBias?: Coordinate;
  customRetailerCard?: RetailerCard;
  capabilities?: string[];
  retailersChunk?: number;
  tracking?: TrackingRecords;
  enableGeo?: boolean;
  enableInfoMessage?: boolean;
  retailerCountryFilter?: string;
  retailerFilter?: (retailer: Retailer) => boolean;
}

export interface RetailerPickerHandle {
  input: HTMLInputElement | null;
  container: HTMLDivElement | null;
  reset: () => void;
  touch: () => void;
}

const RTL_LANGUAGES = new Set(['ar', 'he']);

export const RetailerPickerInner = forwardRef<
  RetailerPickerHandle,
  RetailerPickerInnerProps
>(
  (
    {
      retailerSelected,
      config = {
        volvoCountryCode: undefined,
        isoCountryCode: undefined,
        googleCountryCode: undefined,
      },
      onAutocompleteChange,
      onError,
      required = false,
      preselectedParmaCode,
      smallMarketsGeoBias,
      customRetailerCard,
      capabilities = [],
      retailersChunk = 5,
      tracking,
      enableGeo = true,
      enableInfoMessage = true,
      retailerCountryFilter,
      retailerFilter,
    },
    ref,
  ) => {
    const tracker = useTracker();
    const { siteSlug, regionCode, roadLengthUnit } = useCurrentMarketSite();
    const { languageCode } = getMarketSite(config.volvoCountryCode);
    const {
      volvoCountryCode = siteSlug || 'siteSlug-undefined',
      isoCountryCode = regionCode || 'regionCode-undefined',
    } = config;

    const [isFocused, setIsFocused] = useState(false);
    const [retailers, setRetailers] = useState<Retailer[]>([]);
    const [selectedRetailer, setSelectedRetailer] = useState<Retailer | null>(
      null,
    );
    const [nextRetailers, setNextRetailers] = useState<number | null>(null);
    const [loadingRetailers, setLoadingRetailers] = useState(false);
    const [loadingMoreRetailers, setLoadingMoreRetailers] = useState(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [userLocation, setUserLocation] = useState<Coordinate | undefined>();

    const containerRef = useRef<HTMLDivElement>(null);
    const placesRef = useRef<PlacesHandle>(null);
    const retailersRef = useRef<RetailersHandle>(null);
    const textDirection = RTL_LANGUAGES.has(languageCode) ? 'rtl' : 'ltr';
    const renderRetailerPicker =
      siteSlug !== 'intl' || volvoCountryCode !== 'intl';

    useImperativeHandle(ref, () => ({
      input: placesRef.current?.input || null,
      container: containerRef.current,
      reset,
      touch: () => placesRef.current?.touch(),
    }));

    const onLocationFound = async (
      location: Coordinate,
      placeResult: PlaceAutocompleteResult | null,
      fromGeo: boolean,
    ) => {
      setLoadingRetailers(true);
      setRetailers([]);

      if (fromGeo && tracking?.onSearch && placeResult) {
        tracker.interaction({
          eventLabel: `search|${placeResult.description}`,
          ...tracking.onSearch,
        });
      }

      if (!fromGeo && tracking?.onGeoClicked) {
        if (tracking?.onGeoClicked) {
          tracker.interaction({
            ...tracking.onGeoClicked,
          });
        }
      }

      setUserLocation(location);

      try {
        const { nextPage, retailers } = await fetchRetailers(
          location,
          isoCountryCode,
          null,
          retailersChunk,
          capabilities,
          retailerCountryFilter,
        );
        const filteredRetailers = retailerFilter
          ? retailers.filter(retailerFilter)
          : retailers;
        setRetailers(filteredRetailers);
        setNextRetailers(nextPage);
        retailersRef.current?.focus();
      } catch (error) {
        onError?.('Fetching retailers', error);
        setHasError(true);
      }
      setLoadingRetailers(false);
    };

    const onSelectRetailer = (retailer: Retailer | null) => {
      setRetailers([]);
      setSelectedRetailer(retailer);
      retailerSelected(retailer);
    };

    const reset = (skipLoadingInitialState = false) => {
      placesRef.current?.reset();
      setSelectedRetailer(null);
      setRetailers([]);
      setNextRetailers(null);
      onSelectRetailer(null);
      setHasError(false);

      if (!skipLoadingInitialState) {
        void loadInitialState();
      }
    };

    const onNext = async () => {
      try {
        setLoadingMoreRetailers(true);
        if (!nextRetailers || !userLocation) {
          return;
        }
        const { nextPage, retailers: newRetailers } = await fetchRetailers(
          userLocation,
          isoCountryCode,
          nextRetailers,
          retailersChunk,
          capabilities,
          retailerCountryFilter,
        );
        const filteredNewRetailers = retailerFilter
          ? newRetailers.filter(retailerFilter)
          : newRetailers;
        setRetailers([...retailers, ...filteredNewRetailers]);
        setNextRetailers(nextPage);
        setLoadingMoreRetailers(false);
      } catch (error) {
        onError?.(`Fetching next ${retailersChunk} retailers`, error);
        setLoadingMoreRetailers(false);
        setHasError(true);
      }
    };

    const loadSmallMarketsRetailers = async () => {
      if (!smallMarketsGeoBias) {
        return;
      }
      try {
        setLoadingRetailers(true);
        const { retailers: newRetailers, nextPage } = await fetchRetailers(
          smallMarketsGeoBias,
          isoCountryCode,
          null,
          retailersChunk,
          capabilities,
          retailerCountryFilter,
        );
        setUserLocation({ ...smallMarketsGeoBias });
        const filteredRetailers = retailerFilter
          ? newRetailers.filter(retailerFilter)
          : newRetailers;
        setRetailers(filteredRetailers);
        setNextRetailers(nextPage);
      } catch (error) {
        onError?.('Loading small market retailers', error);
        setHasError(true);
      }
      setLoadingRetailers(false);
    };

    const loadPreselectedRetailer = async () => {
      if (!preselectedParmaCode) {
        return;
      }
      try {
        setLoadingRetailers(true);
        const retailer = await fetchRetailer(
          isoCountryCode,
          preselectedParmaCode,
        );
        setSelectedRetailer(retailer);
        retailerSelected(retailer);
      } catch (error) {
        onError?.('Loading preselected retailers', error);
        setHasError(true);
      }
      setLoadingRetailers(false);
    };

    const loadInitialState = async () => {
      if (preselectedParmaCode) {
        void loadPreselectedRetailer();
        return;
      }
      if (smallMarketsGeoBias) {
        void loadSmallMarketsRetailers();
        return;
      }
    };

    useEffect(() => {
      void loadInitialState();
      // NOTE: to avoid render loops, keep the callbacks out of the dependency array.
      // using useCallback will get complicated as some ot the dependencies of loadPreselectedRetailer
      // and loadSmallMarketsRetailers get touched by onLocationFound, which will cause a loop.
      // These are only supposed to be used for initial state, so stale state should not be an issue.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showSearchField = !selectedRetailer && !smallMarketsGeoBias;

    return (
      <>
        {renderRetailerPicker && (
          <div ref={containerRef} dir={textDirection}>
            {/* The dir={textDirection} is needed below to override the text direction in Storybook */}
            {showSearchField && (
              <PlacesInner
                ref={placesRef}
                loading={loadingRetailers}
                required={required}
                showingData={Boolean(selectedRetailer) || retailers.length > 0}
                onLocationSearchStart={() => setLoadingRetailers(true)}
                onLocationFound={onLocationFound}
                onReset={() => reset()}
                onAutoCompleteChange={onAutocompleteChange}
                onFocusChange={(newIsFocused) => setIsFocused(newIsFocused)}
                config={config}
                hasError={hasError}
                enableGeo={enableGeo}
                enableInfoMessage={enableInfoMessage}
              />
            )}

            <Retailers
              ref={retailersRef}
              visible={!isFocused}
              retailers={retailers}
              selectedRetailer={selectedRetailer}
              nextRetailers={nextRetailers}
              onSelectRetailer={onSelectRetailer}
              loadingNext={loadingMoreRetailers}
              canChange={!preselectedParmaCode}
              onNext={() => void onNext()}
              onClear={() => reset()}
              customRetailerCard={customRetailerCard}
              roadLengthUnit={roadLengthUnit}
              volvoCountryCode={volvoCountryCode}
            />
          </div>
        )}
      </>
    );
  },
);

RetailerPickerInner.displayName = 'RetailerPickerInner';

export interface RetailerPickerProps extends RetailerPickerInnerProps {
  market?: SiteSlug;
  externalTranslations?: Dictionaries;
}

export const RetailerPicker = forwardRef<
  RetailerPickerHandle,
  RetailerPickerProps
>(({ market, externalTranslations, ...props }, ref) => {
  const { siteSlug } = useCurrentMarketSite();
  const marketLanguage = market || siteSlug;

  if (marketLanguage === undefined) {
    throw new Error('Missing market');
  }

  return (
    <RetailerPickerTranslationProvider
      market={marketLanguage}
      externalTranslations={externalTranslations}
    >
      <RetailerPickerInner {...props} ref={ref} />
    </RetailerPickerTranslationProvider>
  );
});

RetailerPicker.displayName = 'RetailerPicker';
