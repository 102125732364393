import { getDictionariesProvider } from '@vcc-www/react-translate';
import { DictionaryItemTypes } from './DictionaryItemTypes';

const providers = getDictionariesProvider<DictionaryItemTypes>();

export const useSharedContentTranslate: ReturnType<
  typeof getDictionariesProvider
>['useTranslate'] = providers.useTranslate;

export const SharedContentDictionariesProvider = providers.DictionariesProvider;
